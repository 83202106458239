import axios from 'axios'
import {Message} from 'element-ui'
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;

// 添加请求拦截器
axios.interceptors.request.use(config => {
  config.headers.loginType = 1;
  let userInfo = window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo'))
  if (userInfo && userInfo.token) config.headers.token = userInfo.token;
  if (userInfo && userInfo.mobile) config.headers.mobile = userInfo.mobile;
  return config
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

let failure = false;

// 添加响应拦截器
axios.interceptors.response.use(response => {
  // 对响应数据做点什么
  if (!response.data.code) {
    if (typeof (response.data.msg) != 'undefined'){
      Message.error(response.data.msg);
    }
    return response
  }
  if (response.data.code !== '100' && response.data.code != 100) {
    if (response.data.code !== 401)
      Message.error(response.data.msg);
    else {
      if (!failure) {
        failure = true;
        Message.error('登录失效');
        router.push({name: 'Login'})
        setTimeout(() => {
          failure = false;
        }, 3000)
      }

    }
  }
  return response
}, error => {
  // 对响应错误做点什么
  if (!failure) {
    failure = true;
    Message.error('操作失败，请稍后再试');
    setTimeout(() => {
      failure = false;
    }, 3000)
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }

});

export default {
  get: function (url, params, callback) {
    axios.get(url, {
      params: params
    }).then(function (response) {
      let data = response.data;
      return callback(data)
    }).catch(function (error) {
      return callback(error)
    })
  },
  post: function (url, params, callback) {
    axios.post(url, params).then(function (response) {
      let data = response.data
      return callback(data)
    }).catch(function (error) {
      return callback(error)
    })
  },
  postForm: function (url, params, callback) {
    let jstring = ''
    for (let key in params) {
      jstring = jstring + key + '=' + params[key] + '&'
    }
    axios.post(url, jstring, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(function (response) {
        let data = response.data
        return callback(data)
      })
      .catch(function (error) {
        return callback(error)
      })
  },
  form:function (url,params,callback) {
    axios.post(url,params,{
      headers: {
        'Content-Type': ' multipart/form-data'
      }
    }).then(function (response) {
      let data = response.data;
      return callback(data);
    }).catch(function (error) {
      return callback(error)
    })
  },
}
