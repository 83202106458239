import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse:'0'
  },
  mutations: {
    changeCollapseStatus(state){
      if(state.isCollapse === '1') {
        state.isCollapse = '0';
        return
      }
      if(state.isCollapse === '0') {
        state.isCollapse = '1';
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
