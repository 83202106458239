import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  /**
   * 协议
   * */
  {
    path: '/protocol',
    name: 'Protocol',
    component: () => import('../views/Protocol')
  },
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/common/Main'),
    children: [
      /**
       * 系统设置
       */
      // 角色列表
      {
        path: '/role',
        name: 'RoleList',
        component: () => import('../views/system/role/RoleList')
      },
      //员工列表
      {
        path: '/staff',
        name: 'StaffList',
        component: () => import('../views/system/staff/StaffList')
      },
      //新增/编辑员工
      {
        path: '/updateStaff',
        name: 'AddStaff',
        component: () => import('../views/system/staff/AddStaff')
      },
      //问题配置
      {
        path:'/questionConfig',
        name:'Question',
        component:() => import('../views/system/question/Question')
      },
      /**
       * 租赁设备管理
       */
      //设备列表
      {
        path: '/device',
        name: 'DeviceList',
        component: () => import('../views/device/DeviceList')
      },
      //添加设备
      {
        path: '/addDevice',
        name: 'AddDevice',
        component: () => import('../views/device/AddDevice')
      },
      //设备详情
      {
        path: '/deviceDetail',
        name: 'DeviceDetail',
        component: () => import('../views/device/DeviceDetail')
      },
      //心跳记录
      {
        path:'/heartRecord',
        name:'HeartRecord',
        component:() => import('../views/device/HeartRecord')
      },
      //心跳记录
      {
        path:'/heartRecordChair',
        name:'HeartRecordChair',
        component:() => import('../views/Chairdevice/HeartRecord')
      },
      /**
       * 设备绑定管理
       * */
      //设备绑定列表
      {
        path: '/deviceBinding',
        name: 'DeviceBindingList',
        component: () => import('../views/deviceBinding/DeviceBindingList')
      },
      //轮椅绑定
      {
        path: '/ChairdeviceBinding',
        name: 'WheelchairDeviceBindingList',
        component: () => import('../views/ChairdeviceBinding/ChairDeviceBindingList')
      },
      //设备初始化
      {
        path: '/initDevice',
        name: 'InitDevice',
        component: () => import('../views/deviceBinding/InitDevice')
      },
      //轮椅初始化
      {
        path: '/ChairInitDevice',
        name: 'ChairInitDevice',
        component: () => import('../views/ChairdeviceBinding/ChairInitDevice')
      },
      //轮椅设备管理
      {
        path: '/ChairDevice',
        name: 'ChairDevice',
        component: () => import('../views/Chairdevice/DeviceList')
      },
      //轮椅设备详情
      {
        path: '/ChairdeviceDetail',
        name: 'ChairdeviceDetail',
        component: () => import('../views/Chairdevice/ChairDeviceDetail')
      },
      /**
       *  订单管理
       */
      //订单列表
      {
        path: '/order',
        name: 'OrderList',
        component: () => import('../views/order/OrderList')
      },
      {
        path: '/Chairorder',
        name: 'Chairorder',
        component: () => import('../views/Chairorder/OrderList')
      },
      //订单详情
      {
        path: '/orderDetail',
        name: 'OrderDetail',
        component: () => import('../views/order/OrderDetail')
      },
      /**
       * apk管理  wheelchair
       * apk管理
       * */
      {
        path:'/apk',
        name:'ApkList',
        component:() => import('../views/apk/ApkList')
      },
      /**
       * 场地管理
       * */
      //场地列表
      {
        path: '/site',
        name: 'SiteList',
        component: () => import('../views/site/SiteList')
      },
      //新增单位
      {
        path: '/addUnit',
        name: 'AddUnit',
        component: () => import('../views/site/AddUnit')
      },
      //单位详情
      {
        path: '/unitDetail',
        name: 'UnitDetail',
        component: () => import('../views/site/UnitDetail')
      },
      //价格配置
      {
        path: '/priceDetail',
        name: 'PriceDetail',
        component: () => import('../views/site/PriceDetail')
      },
      {
        path: '/AddPosition',
        name: 'AddPosition',
        component: () => import('../views/site/AddPosition')
      },
      {
        path: '/updatePosition/:id',
        name: 'UpdateddPosition',
        component: () => import('../views/site/AddPosition')
      },
      /**
       * 用户管理
       * */
      //用户列表
      {
        path: '/user',
        name: 'UserList',
        component: () => import('../views/user/UserList')
      },
      /**
       * 异常管理
       * */
      //设备异常
      {
        path: '/deviceAbnormal',
        name: 'DeviceAbnormal',
        component: () => import('../views/abnormal/DeviceAbnormal')
      },
      //订单异常
      {
        path: '/orderAbnormal',
        name: 'OrderAbnormal',
        component: () => import('../views/abnormal/OrderAbnormal')
      },
      //离线归还记录
      {
        path:'/offlineReturnRecord',
        name: 'OfflineReturnRecord',
        component:() => import('../views/abnormal/OfflineReturnRecord')
      },
      //扫描异常
      {
        path:'/scanAbnormal',
        name: 'ScanAbnormal',
        component:() => import('../views/abnormal/ScanAbnormal')
      },
      //自动异常
      {
        path:'/autoAbnormal',
        name: 'AutoAbnormal',
        component:() => import('../views/abnormal/AutoAbnormal')
      },
      // {
      //   path: '/acl',
      //   // component: Layout,
      //   redirect: '/acl/user/list',
      //   name: '权限管理',
      //   meta: {title: '权限管理', icon: 'chart'},
      //   children: [
      {
        path: '/acl/user/list',
        name: '用户管理',
        component: () => import('../views/acl/user/list')
        // component: () => import('../views/device/DeviceList')
      },
      {
        path: '/acl/role/list',
        name: '角色管理',
        component: () => import('../views/acl/role/list'),
      },
      {
        path: '/acl/role/form',
        name: '角色添加',
        component: () => import('@/views/acl/role/form'),
        meta: {title: '角色添加'},
        hidden: true
      },
      {
        path: '/acl/role/update/:id',
        name: '角色修改',
        component: () => import('@/views/acl/role/form'),
        meta: {title: '角色修改'},
        hidden: true
      },
      {
        path: '/acl/role/distribution/:id',
        name: '角色权限',
        component: () => import('@/views/acl/role/roleForm'),
        meta: {title: '角色权限'},
        hidden: true
      },
      {
        path: '/acl/menu/list',
        name: '菜单管理',
        component: () => import('@/views/acl/menu/list'),
        meta: {title: '菜单管理'}
      },
      {
        path: '/acl/user/add',
        name: '用户添加',
        component: () => import('@/views/acl/user/form'),
        meta: {title: '用户添加'},
        hidden: true
      },
      {
        path: '/acl/user/update/:id',
        name: '用户修改',
        component: () => import('@/views/acl/user/form'),
        meta: {title: '用户修改'},
        hidden: true
      },
      {
        path: '/acl/user/role/:id',
        name: '用户角色',
        component: () => import('@/views/acl/user/roleForm'),
        meta: {title: '用户角色'},
        hidden: true
      },
      //订单异常
      {
        path: '/abnormal/items/auxiliaryAbnormal',
        name: 'AuxiliaryAbnormal',
        component: () => import('../views/abnormal/items/AuxiliaryAbnormal')
      },
      {
        path: '/abnormal/items/RfidAbnormal',
        name: 'AuxiliaryAbnormal',
        component: () => import('../views/abnormal/items/RfidAbnormal')
      },
      //广告列表
      {
        path: '/adlist',
        name: 'AdList',
        component: () => import('../views/advertisement/AdList')
      },
      //新增广告
      {
        path: '/adlist/addad',
        name: 'AddAd',
        component: () => import('../views/advertisement/AddAd')
      },
      //更新广告
      {
        path: '/adlist/editad',
        name: 'EditAd',
        component: () => import('../views/advertisement/AddAd')
      },
      //订单统计
      {
        path: '/orderStatistics',
        name: 'OrderStatistics',
        component: () => import('../views/statistics/OrderStatistics')
      },
        //押金统计
      {
        path: '/depositStatistics',
        name: 'DepositStatistics',
        component: () => import('../views/statistics/DepositStatistics')
      },
      //收入统计
      {
        path: '/incomeStatistics1',
        name: 'IncomeStatistics1',
        component: () => import('../views/statistics/IncomeStatistics1')
      },
      {
        path: '/incomeStatistics2',
        name: 'IncomeStatistics2',
        component: () => import('../views/statistics/IncomeStatistics2')
      },
      {
        path: '/incomeStatistics3',
        name: 'IncomeStatistics3',
        component: () => import('../views/statistics/IncomeStatistics3')
      },
      {
        path: '/incomeStatistics',
        name: 'IncomeStatistics',
        component: () => import('../views/statistics/IncomeStatistics')
      },
      {
        path: '/profitStatistics',
        name: 'ProfitStatistics',
        component: () => import('../views/statistics/ProfitStatistics')
      },
      {
        path: '/profitStatistics1',
        name: 'ProfitStatistics1',
        component: () => import('../views/statistics/ProfitStatistics1')
      },
      {
        path: '/profitStatistics2',
        name: 'ProfitStatistics2',
        component: () => import('../views/statistics/ProfitStatistics2')
      },
      {
        path: '/profitStatistics3',
        name: 'ProfitStatistics3',
        component: () => import('../views/statistics/ProfitStatistics3')
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard')
      },
      {
        path: '/chairConfig',
        name: 'ChairConfig',
        component: () => import('../views/site/ChairConfig')
      },
      {
        path: '/chairOrderDetail',
        name: 'ChairOrderDetail',
        component: () => import('../views/Chairorder/OrderDetail')
      },
      {
        path: '/work/refundWorks',
        name: 'RefundWorkList',
        component: () => import('../views/workorder/RefundWorkList')
      },
      {
        path: '/work/complaintWorks',
        name: 'CustomerComplaintList',
        component: () => import('../views/workorder/CustomerComplaintList')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
